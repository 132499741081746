<template>
  <div class="index">
    <div class="container">
      <Menu :active="1"/>
      <div class="main">
        <Header @getContract="getContract"/>
        <div class="welcome">
          <span>Welcome to,</span> My World
        </div>
        <div class="banner">
          <svg xmlns="http://www.w3.org/2000/svg" width="889" height="206" viewBox="0 0 889 206" fill="none">
            <g filter="url(#filter0_i_261_16124)">
              <path d="M0.888672 10.7526C0.888672 4.99963 5.55237 0.335938 11.3053 0.335938H877.972C883.725 0.335938 888.389 4.99964 888.389 10.7526V194.78C888.389 200.533 883.725 205.197 877.972 205.197H11.3054C5.55239 205.197 0.888672 200.533 0.888672 194.78V10.7526Z" fill="#06C8A1"/>
            </g>
            <path d="M1.93034 10.7526C1.93034 5.57493 6.12767 1.3776 11.3053 1.3776H877.972C883.15 1.3776 887.347 5.57494 887.347 10.7526V194.78C887.347 199.958 883.15 204.155 877.972 204.155H11.3054C6.12768 204.155 1.93034 199.958 1.93034 194.78V10.7526Z" stroke="url(#paint0_linear_261_16124)" stroke-width="2.08333"/>
            <defs>
              <filter id="filter0_i_261_16124" x="-3.27799" y="0.335938" width="891.667" height="207.637" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="-4.16667" dy="2.77778"/>
                <feGaussianBlur stdDeviation="10.4167"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_261_16124"/>
              </filter>
              <linearGradient id="paint0_linear_261_16124" x1="444.639" y1="0.335937" x2="444.639" y2="205.197" gradientUnits="userSpaceOnUse">
                <stop stop-color="#ADFFEF"/>
                <stop offset="1" stop-color="#06C8A1"/>
              </linearGradient>
            </defs>
          </svg>
          <div class="wrap">
            <div class="left">
              <div class="voucher">
                <img src="@/assets/imgs/icon_voucher.png">
                <div class="text">
                  Your Vouchers<span>Start Earning Upoints</span>
                </div>
              </div>
              <!--有NFT-->
              <div v-if="nftList.length" class="nft">
                <img :class="{no: aIndex == 0}" class="arrow" src="@/assets/imgs/icon_arrowL.png" @click="prev">
                <div class="card">
                  <div class="list" :style="'width:' + 255 * nftList.length + 'px; transform:translateX(' + (-aIndex * 255) + 'px)'">
                    <div v-for="(item,index) in nftList" :key="index" class="item">
                      <div class="text">
                        <div class="name">{{ item.name }}</div>
                        <div class="price">${{ item.price }}</div>
                        <div class="new">{{ item.type }}</div>
                        <div class="balance">
                          <img src="@/assets/imgs/icon_star.png">
                          <div class="num">Balance: {{ item.balance }}</div>
                        </div>
                      </div>
                      <img class="nftImg" :src="item.imgUrl">
                    </div>
                  </div>
                </div>
                <img :class="{no: aIndex == nftList.length - 1}" class="arrow right" src="@/assets/imgs/icon_arrowL.png" @click="next">
              </div>
              <!--无NFT-->
              <img v-else class="noNft" src="@/assets/imgs/noNft.png">
            </div>
            <div class="right">
              <div class="title">Ignite Your Metaverse Journey,<br/>Earn Exclusive Rewards!</div>
              <div class="tips">Choose a Voucher & Redeem your product</div>
              <div class="btn" @click="toPro">Redeem</div>
            </div>
          </div>
        </div>
        <div class="super">
          <div class="left bWrap">
            <img class="bg" src="@/assets/imgs/nft_bg2.png" alt="">
            <div class="now">
              <img src="@/assets/imgs/icon_now.png">
              <div class="text">
                Available Now!<span>Join the Community Now!</span>
              </div>
            </div>
            <div class="title">Explore endless virtual<br/>possibilities with<br/>Super App.</div>
            <div class="tips">World’s First Metaverse Loyalty Programme</div>
            <a href="https://web.my-world.io/#/capital" class="btn">Purchase</a>
          </div>
          <div class="right bWrap">
            <div class="title">
              <img src="@/assets/imgs/icon_mini.png">
              <div class="text">Mini App hub</div>
            </div>
            <div class="list">
              <div v-for="(item,index) in menuList" :key="index" class="item" @click="handleClick(item.url)">
                <img :src="item.images">
                <div class="text">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--银行卡弹框-->
    <div ref="modal" class="modal">
      <a-modal
          v-model:visible="visible"
          centered
          width="305px"
          :footer="null"
          :closable="false"
          :get-container="() => $refs.modal"
      >
        <div class="bankCard">
          <a href="#/card">
            <img src="@/assets/imgs/icon_add.png">
            <span class="text">ACTIVATE NEW CARD</span>
          </a>
          <a href="https://www.metagenesis-card.com">
            <img src="@/assets/imgs/icon_global.png">
            <span class="text">ENTER META GENESIS</span>
          </a>
          <div class="back" @click="visible = false">&lt; Back</div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { getMenus } from '@/request/api'
  import { message } from 'ant-design-vue'

  const visible = ref(false)
  const router = useRouter()

  const aIndex = ref(0)
  function next() {
    if(aIndex.value >= nftList.value.length - 1) return
    aIndex.value++
  }
  function prev() {
    if(aIndex.value <= 0) return
    aIndex.value--
  }

  let blockNftContract = null
  const nftList = ref([])
  let arr = []
  async function getContract(obj) {
    nftList.value = []
    blockNftContract = obj.blockNftContract
    const nftArr= [
      { id: 22, name: 'META BASE', price: 50, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft11.png') },
      { id: 30, name: 'RAMS EXPLORER', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft1.png') },
      { id: 28, name: 'Play 2 Earn', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft14.png') },
      { id: 23, name: 'META NET', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft12.png') },
      { id: 11, name: 'EXPLORER', price: 100, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft1.png') },
      { id: 18, name: 'DIGITAL BANK ACC', price: 100, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft8.png') },
      { id: 26, name: 'META CRYPTO', price: 200, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft13.png') },
      { id: 24, name: 'MY WORLD FX', price: 200, type: 'BASIC NFT', imgUrl: require('@/assets/imgs/nft9.png') },
      { id: 27, name: 'MY WORLD FX', price: 200, type: 'TESTING NFT', imgUrl: require('@/assets/imgs/nft9.png') },
      { id: 29, name: 'RAMS DISCOVERER', price: 300, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft15.png') },
      { id: 12, name: 'ADVENTURER', price: 500, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft2.png') },
      { id: 25, name: 'MY WORLD FX', price: 600, type:'PREMIUM NFT', imgUrl: require('@/assets/imgs/nft10.png') },
      { id: 13, name: 'INFLUENCER', price: 1000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft3.png') },
      { id: 14, name: 'PIONEER', price: 3000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft4.png') },
      { id: 15, name: 'NAVIGATOR', price: 5000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft5.png') },
      { id: 16, name: 'MASTER', price: 10000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft6.png') },
      { id: 17, name: 'LEGEND', price: 20000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft7.png') }
    ]
    // obj.address = '0xF0c53D34f14B8c0AddEfBeedFBF4c6Ba829B9882'
    // obj.address = '0xcd0728526a9cf0658ca311f8e7e955ee2e7e91ce'
    for (let i = 0; i <= nftArr.length - 1; i++) {
      const nftBanner = parseInt(await blockNftContract.balanceOf(obj.address, nftArr[i].id))
      if(nftBanner > 0) {
        arr.push({
          nft_id: nftArr[i].id,
          name: nftArr[i].name,
          price: nftArr[i].price,
          type: nftArr[i].type,
          balance: nftBanner,
          imgUrl: nftArr[i].imgUrl
        })
      }
    }
    nftList.value = arr
  }

  function toPro() {
    if(!nftList.value.length) {
      message.warning('No NFT')
      return
    }
    router.push('/product?id=' + nftList.value[aIndex.value].nft_id)
  }

  const menuList = ref([])
  getMenus().then(res => {
    if(res.success) {
      menuList.value = res.data
    }
  })

  function handleClick(url) {
    if(url == 2) {
      visible.value = true
    } else if(url == 1) {
      router.push('/coming')
    } else {
      window.location.href = url
    }
  }
</script>

<style lang="less" scoped>
.index{
  min-height: 100vh;
  background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);

  .container{
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .main{
      width: 888px;
      padding-top: 20px;

      .welcome{
        font-size: 33px;
        margin: 54px 0 27px 0;

        span{
          color: #697377;
        }
      }
      .banner{
        height: 205px;
        position: relative;
        margin-bottom: 22px;

        .wrap{
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          padding: 20px 20px 0 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left{
            .voucher{
              display: flex;
              align-items: center;

              img{
                width: 26px;
                margin-right: 15px;
              }
              .text{
                font-size: 17px;

                span{
                  font-size: 12px;
                  color: #697377;
                  margin-left: 10px;
                }
              }
            }
            .nft{
              margin-top: 16px;
              display: flex;
              align-items: center;

              .arrow{
                width: 17px;
                cursor: pointer;
              }
              .no{
                cursor: not-allowed;
              }
              .right{
                transform: rotate(180deg);
              }
              .card{
                width: 255px;
                height: 124px;
                overflow: hidden;
                margin: 0 30px 0 25px;

                .list{
                  display: flex;
                  transition: all 0.3s;

                  .item{
                    width: 255px;
                    height: 124px;
                    background: url("~@/assets/imgs/nft_bg.png") no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    align-items: center;
                    padding-left: 22px;
                    color: #697377;
                    position: relative;

                    .text{
                      .name{
                        font-size: 13px;
                        color: #000000;
                        font-weight: bold;
                      }
                      .price{
                        font-size: 20px;
                        color: #06C8A1;
                        font-weight: bold;
                        margin: 6px 0 4px 0;
                      }
                      .new{
                        font-size: 12px;
                        margin-bottom: 10px;
                      }
                      .balance{
                        display: flex;
                        align-items: center;
                        font-size: 12px;

                        img{
                          width: 20px;
                          margin-right: 5px;
                        }
                      }
                    }
                    .nftImg{
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 14px;
                      width: 122px;
                    }
                  }
                }
              }
            }
            .noNft{
              width: 355px;
              margin: 10px 0 0 40px;
            }
          }
          .right{
            color: #000000;
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .title{
              font-size: 28px;
              line-height: 32px;
              margin-bottom: 10px;
            }
            .tips{
              color: #697377;
              font-size: 17px;
              margin-bottom: 32px;
            }
            .btn{
              width: 227px;
              height: 35px;
              border-radius: 10px;
              background: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              font-family: 'DM Sans Bold';
              cursor: pointer;
            }
          }
        }
      }
      .super{
        display: flex;
        justify-content: space-between;

        .left{
          width: 592px;
          min-height: calc(100vh - 437px);
          padding: 20px 0 0 20px;
          position: relative;

          .bg{
            width: 316px;
            position: absolute;
            top: 50px;
            right: 16px;
          }
          .now{
            display: flex;
            align-items: center;
            margin-bottom: 33px;

            img{
              width: 28px;
              margin-right: 14px;
            }
            .text{
              font-size: 17px;

              span{
                font-size: 12px;
                color: #697377;
                margin-left: 10px;
              }
            }
          }
          .title{
            font-size: 28px;
            line-height: 31px;
            padding-left: 7px;
            margin-bottom: 6px;
          }
          .tips{
            font-size: 17px;
            color: #697377;
            padding-left: 7px;
            margin-bottom: 62px;
          }
          .btn{
            width: 227px;
            height: 35px;
            border-radius: 10px;
            background: #06C8A1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #000000;
            font-family: 'DM Sans Bold';
            cursor: pointer;
          }
        }
        .right{
          width: 276px;
          min-height: calc(100vh - 438px);
          padding-top: 20px;

          .title{
            display: flex;
            align-items: center;
            margin-bottom: 22px;
            padding-left: 20px;

            img{
              width: 28px;
              margin-right: 14px;
            }
            .text{
              font-size: 17px;
            }
          }
          .list{
            padding: 0 13px;
            display: flex;
            flex-wrap: wrap;

            .item{
              text-align: center;
              width: 54px;
              margin: 0 10px 20px 0;
              cursor: pointer;

              img{
                width: 42px;
                height: 42px;
                margin: 0 auto 6px auto;
                border-radius: 14px;
              }
              .text{
                color: #697377;
                font-size: 12px;
              }
            }
            .item:hover{
              .text{
                color: #06C8A1;
              }
            }
            .item:nth-child(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.modal{
  ::v-deep .ant-modal-content{
    background: transparent;
    box-shadow: none;
  }
  .bankCard{
    font-size: 17px;

    a{
      height: 50px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 0 28px 0 13px;
      color: #ffffff;
      justify-content: space-between;
      margin-bottom: 24px;
      border: 2px solid #06C8A1;
      transition: all 0.3s;

      img{
        width: 31px;
      }
    }
    a:hover{
      background: #06C8A1;
    }
    .back{
      color: #697377;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
    }
    .back:hover{
      color: #06C8A1;
    }
  }
  ::v-deep .ant-modal-mask{
    background: rgba(0, 0, 0, 0.9);
  }
}
</style>
